/* .App {
  text-align: center;
  padding: 64px max(5vw, 24px);
  overflow-y: visible;
  max-width: 520px; 
  margin: auto; 
} */


body > #root > div {
  display: flex; 
  flex-direction: column;
  flex: 1; 
  align-items: stretch; 
  height: 100vh; 
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Section {
  margin: 16px 0px !important; 
}

.react-datepicker-wrapper, .react-datepicker__input-container {
  display: block; 
  width: 100%; 
  flex: 1; 
}

.rv-xy-plot {
  margin: 0px auto; 
}

.MuiFormControl-root {
  display: block !important; 
  margin: auto auto !important; 
  text-align: center !important;
}

.MuiInputBase-root,.MuiOutlineInput-root,.MuiInputBase-formControl {
  display: block !important; 
  margin: 0px auto !important; 
  line-height: 18px;
}

.MuiOutlinedInput-input {
  font-size: 14px !important; 
  line-height: 18px;
}

.MuiSelect-select {
  padding-right: 0px !important; 
  line-height: 18px !important; 
} 

.MuiInputBase-input {
  width: stretch !important; 
  line-height: 18px;
}

.rv-xy-plot__series--label {
  fill: #efefef; 
}

.highcharts-data-table table {
  font-family: 'Eina01';
}

div.react-datepicker-popper {
  z-index: 3000;
}

.bm-overlay {
  position: relative !important; 
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 24px;
  height: 24px;
  right: 24px;
  top: 24px;
  z-index: 3000; 
}

/* Color/shape of burger icon bars */
.bm-burger-bars:focus {
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  position: fixed !important;
  width: 24px !important;
  height: 24px !important;
  right: 24px !important;
  top: 24px !important;
  z-index: 3000 !important; 
}

.bm-cross-button:focus {
  
}

/* Color/shape of close button cross */
.bm-cross {
  background: none;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  transition: 180ms !important; 
}

/* General sidebar styles */
.bm-menu {
  background: #ffffff;
  /* padding: 2.5em 1.5em 0; */
  font-size: 1.15em; 
  height: calc(100vh - 96px); 
  overflow: hidden !important; 
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

.MuiTable-root {
  border-collapse: separate !important; 
}

.MuiIconButton-colorPrimary {
  color: #273561; 
}

.refresh-button {
  position: fixed !important; 
  left: 24px; 
  top: 24px; 
  width: 24px; 
  height: 24px; 
}

ul {
  text-align: left; 
  font-size: 13px;
  list-style-position: outside; 
  list-style-type: circle; 
}

ul {
  list-style: none; 
  text-align: left;
}

ul li::before {
  content: "\2022"; 
  color: #273561; 
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

ul li {
  margin-bottom: 6px;
  color: #646C77; 
}

select {
  font-family: 'Eina01'; 
  background-color: #ffffff;
  line-height: 18px;
}

.header-concept-logo {
  width: 132px; 
  height: 54px; 
  object-fit: contain;
  object-position: 0 50%;
}

.thumbnail-concept-logo {
  width: 96px; 
  height: 52px; 
  object-fit: contain;
  align-self: flex-end; 
  margin-bottom: -18px; 
  margin-top: -12px; 
}

._loading_overlay_wrapper {
  display: flex; 
  flex: 1; 
}

.fade-in {
  animation: fadeIn ease 20s;
  -webkit-animation: fadeIn ease 20s;
  -moz-animation: fadeIn ease 20s;
  -o-animation: fadeIn ease 20s;
  -ms-animation: fadeIn ease 20s;
}

@keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-moz-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-o-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-ms-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

input, input[type="number"] {
  padding: 8px; 
  font-family: 'Eina01'; 
  color: #646C77; 
  -moz-appearance: textfield; 
  line-height: 18px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
