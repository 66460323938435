@font-face {
  font-family: 'Eina01';
  font-style: normal; 
  font-weight: 600;
  src: 
    url(../src/fonts/Eina01-Bold.otf) format('opentype'), 
    local(Eina01-Bold); 
}

@font-face {
  font-family: 'Eina01';
  font-style: normal; 
  font-weight: 300;
  src: 
    url(../src/fonts/Eina01-Light.otf) format('opentype'), 
    local(Eina01-Light); 
}

@font-face {
  font-family: 'Eina01';
  font-style: normal; 
  font-weight: 400;
  src: 
    url(../src/fonts/Eina01-Regular.otf) format('opentype'), 
    local(Eina01-Regular); 
}

@font-face {
  font-family: 'Eina01';
  font-style: normal; 
  font-weight: 500;
  src: 
    url(../src/fonts/Eina01-SemiBold.otf) format('opentype'), 
    local(Eina01-SemiBold); 
}

@font-face {
  font-family: 'Omnes';
  font-style: normal;
  font-weight: 400;
  src:
    local(Omnes-Regular),
    url(../src/fonts/Omnes-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Livory';
  font-style: normal;
  font-weight: 400;
  src: 
    url(../src/fonts/Livory-Regular.otf) format('opentype')
    local(Livery-Regular)
}

body {
  font-family: 'Eina01';
  /* font-family: 'Eina', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;  */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto; 
  /* max-width: 560px; */
  margin: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}